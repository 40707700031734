<template>
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.91683 1.25L4.00016 4.16667L1.0835 1.25"
      stroke="white"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      transform="rotate(180 4 2.5)"
    />
  </svg>
</template>
